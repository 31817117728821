import Vue from 'vue'
import {initApi} from './api'

const apiConfig = {
    url: 'https://api.vrsteps.io'
}

initApi(apiConfig); // Ensure API is initialized first

// Only after initializing the API, import the main App component
const App = require('./App.vue').default;

import BootstrapVue from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import Vuelidate from 'vuelidate'
import VueSweetalert2 from 'vue-sweetalert2'
// import { firestorePlugin } from 'vuefire'
import VueMask from 'v-mask'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueYoutube from 'vue-youtube'
import {library} from '@fortawesome/fontawesome-svg-core'
import {
    faUserSecret,
    faCircleInfo,
    faClock,
    faCalendar,
    faMessage,
    faDesktop,
    faPaintbrush,
    faUsers,
    faStore,
    faMap,
    faChartLine,

} from '@fortawesome/free-solid-svg-icons'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faCircleInfo, faClock, faCalendar, faMessage, faDesktop, faPaintbrush, faUsers, faStore, faMap, faChartLine)
Vue.component('font-awesome-icon', FontAwesomeIcon)
import vco from 'v-click-outside'
import router from './router'
import store from '@/state/store'
import i18n from './i18n'
import '@/assets/scss/app.scss'

Vue.config.productionTip = false
Vue.use(VueYoutube)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2)
Vue.use(VueMask)
Vue.use(require('vue-chartist'))
Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDlFF2ONXpZU3_7EbBXT4pqi5fhkPXIncg', libraries: 'places',
    }, installComponents: true
})
// Vue.use(firestorePlugin)
Vue.component('apexchart', VueApexCharts)

new Vue({
    router, store, i18n, render: h => h(App)
}).$mount('#app')
